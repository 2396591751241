import axios from 'axios';
import React from 'react'
import {  useNavigate } from 'react-router-dom';

export default function Icon(props) {
   
   const navigate = useNavigate();
   const onClickHundler=()=>{
      console.log("onclok hundler icons  "+props.mode);
      if(props.mode==='delete'){
         
         if(window.confirm("Czy na pewno chcesz usunąć ten element?")){
            deleteProcess();
         }
         
      }else if(props.mode==='edit'){
         editProcess();
      }else if(props.mode==='card'){
         cardProcess();
      }else if(props.mode==='download'){
         downloadProcess();
      }
   
     
   }
   const deleteProcess =()=>{
    //  console.log("delete deleteProcess000000000000000000 ");
      axios.delete(props.url, {
         withCredentials: true,
         headers: {
             'Content-Type': 'application/json',
         },
     }
      ).then(response => {
         props.onRefreshHundler();
      })
      .catch(err => {
         console.log('Błąd podczas wysyłania żądania:', err.message);
      });
   }
   const editProcess =()=>{
      props.stateHundler('edit',props.id);
   }
   const cardProcess =()=>{
      navigate(props.url );
   }
   const downloadProcess =()=>{
      console.log(" download url: "+props.item.path);
      var filePath = ''+props.item.path;
      window.location.href = filePath;
     //const newWindow = window.open(filePath);

      // newWindow.onload = function() {
      //     newWindow.document.body.innerHTML = '<object data="' + filePath + '" type="application/pdf" width="100%" height="100%"></object>';
      // };
     // window.open('localhost:88'+props.item.path);
     // navigate(props.item.path);
   }
  return (
    <span className={props.className} data-url={props.url} onClick={onClickHundler}></span>
  )
}
