import axios from 'axios';
import Layout from '../shared/Layout';
import { useContext, useEffect, useState } from 'react';
import DetailsContext from '../../providers/DetailsContext';
import language from '../../variables/language';
import { useLocation, Link, useParams  } from 'react-router-dom';
import Header from '../shared/Header';
import Form from '../shared/Form';
import ActivityList from '../../components/settings/ActivityList';
import ModalUpdate from '../shared/ModalUpdate';
const CarDocuments = () => {
  // const [params, setParams] = useState({});
  // const API_URL = process.env.REACT_APP_API_URL;
  // const moduleName = 'firmy';
  const label = 'carDocuments';
  const headerName = 'carDocuments';
  const { id } = useParams();
  const url = '/api/cardocuments/bycar/'+id;
  const urlRefer = '/api/cardocuments';
 // const { setTheme } = useContext(DetailsContext);
 const location = useLocation();
 const [modalState ,setModalState] = useState('none');
 const [refresh, setRefresh] = useState(0);
 const [toUpdateId , setToUpdateId] = useState('');

 const [headers, setHeaders] = useState(
   {'name':{},'number':{},'dateFrom':{}, 'dateTo':{},'carRegistrNum':{},'icons':{
     'icon-delete':{'name':'icon-delete','type' : 'icon','url':urlRefer,'value' : '','mode':'delete'},
     'icon-edit':{'name':'icon-edit','type' : 'icon','url':urlRefer,'value' : '','mode':'edit'},
     'icon-download':{'name':'icon-download','type' : 'icon','url':'/api/cardocuments/bycar/','value' : '','mode':'download'},
   }}
   );
 const [inputs, setInputs] = useState(
   {'name':{'name':'name','type' : 'text','value' : '',},
   'number':{'name':'number','type' : 'text','value' : ''},
    'dateFrom':{'name':'dateFrom','type' : 'date','value' : ''},
    'dateTo':{'name':'dateTo','type' : 'date','value' : ''},
    
   });
 const [selectors, setSelectors] = useState({
   });
 const [inputsToUpdate, setInputsToUpdate] = useState(
   {  'name':{'name':'name','type' : 'text','value' : '','disabled' : 'true'},
      'number':{'name':'number','type' : 'text','value' : '','disabled' : 'true'},
      'carRegistrNum':{'name':'carRegistrNum','type' : 'text','value' : '','disabled' : 'true'},
      'dateFrom':{'name':'dateFrom','type' : 'date','value' : '','disabled' : 'false'},
      'dateTo':{'name':'dateTo','type' : 'date','value' : '','disabled' : 'false'},
     
   });
 const [selectorsToUpdate, setSelectorsToUpdate] = useState({
   });


   const stateHundler = (state,id) => {
     if(state === 'edit'){
       setModalState('edit');
       console.log("ID:"+id);
       setToUpdateId(id);
     }else if(state === 'none'){
       setModalState('none');
     }
   }
   const onRefreshHundler = ()=> {
     if(refresh==="1"){
       setRefresh('0');
     }else{
       setRefresh('1');
     }
     
   }


return (
 <Layout>
   <Header name={language[language.lang][headerName]}   opened='300px' >
     <Form inputs={inputs} selectors={selectors} url='/api/cardocuments' onRefreshHundler={onRefreshHundler} name='Tutaj możesz dodać nowy documetnt'></Form>
   </Header>
   <main className='main-block' >
     <div className='main-content txt-c' >
     <ActivityList name='' url={url} done={refresh} stateHundler={stateHundler} onRefreshHundler={onRefreshHundler} headers={headers}/>
     </div>  
     <ModalUpdate modalState={modalState} inputs={inputsToUpdate}  selectors={selectorsToUpdate}  url='/api/cardocuments' toUpdate={'/api/cardocuments/'+ toUpdateId }  onRefreshHundler={onRefreshHundler} stateHundler={stateHundler} name={language[language.lang].modals[label].updateDescription} title={language[language.lang].modals[label].updateTitle} />
   </main>

 </Layout>
)
}

export default CarDocuments