import axios from 'axios';
import Layout from '../shared/Layout';
import { useContext, useEffect, useState } from 'react';
import DetailsContext from '../../providers/DetailsContext';
import language from '../../variables/language';
import { useLocation, Link  } from 'react-router-dom';
import Header from '../shared/Header';
import Form from '../shared/Form';
import ActivityList from '../../components/settings/ActivityList';
import ModalUpdate from '../shared/ModalUpdate';
const Cars = () => {
  // const [params, setParams] = useState({});
  // const API_URL = process.env.REACT_APP_API_URL;
  // const moduleName = 'firmy';
  const label = 'cars';
  const url = '/api/cars';
 // const { setTheme } = useContext(DetailsContext);
 const location = useLocation();
 const [modalState ,setModalState] = useState('none');
 const [refresh, setRefresh] = useState(0);
 const [toUpdateId , setToUpdateId] = useState('');

 const [headers, setHeaders] = useState(
   {'mark':{},'model':{}, 'color':{},'registrNum':{},'icons':{
     'icon-delete':{'name':'icon-delete','type' : 'icon','url':url,'value' : '','mode':'delete'},
     'icon-edit':{'name':'icon-edit','type' : 'icon','url':url,'value' : '','mode':'edit'},
     'icon-card':{'name':'icon-card','type' : 'icon','url':'/cardocuments/bycar','value' : '','mode':'card'},
   }}
   );
 const [inputs, setInputs] = useState(
   {'mark':{'name':'mark','type' : 'text','value' : ''},
   'model':{'name':'model','type' : 'text','value' : ''},
    'registrNum':{'name':'registrNum','type' : 'text','value' : ''},
    'color':{'name':'color','type' : 'text','value' : ''},
    
   });
 const [selectors, setSelectors] = useState({
   });
 const [inputsToUpdate, setInputsToUpdate] = useState(
   {'mark':{'name':'mark','type' : 'text','value' : ''},
      'model':{'name':'model','type' : 'text','value' : ''},
      'registrNum':{'name':'registrNum','type' : 'text','value' : ''},
      'color':{'name':'color','type' : 'text','value' : ''},
     
   });
 const [selectorsToUpdate, setSelectorsToUpdate] = useState({
   });


   const stateHundler = (state,id) => {
     if(state === 'edit'){
       setModalState('edit');
       console.log("ID:"+id);
       setToUpdateId(id);
     }else if(state === 'none'){
       setModalState('none');
     }
   }
   const onRefreshHundler = ()=> {
     if(refresh==="1"){
       setRefresh('0');
     }else{
       setRefresh('1');
     }
     
   }


return (
 <Layout>
   <Header name={language[language.lang][(location.pathname.replace("/",""))]}   opened='300px' >
     <Form inputs={inputs} selectors={selectors} url='/api/cars' onRefreshHundler={onRefreshHundler} name='Tutaj możesz dodać nowy project'></Form>
   </Header>
   <main className='main-block' >
     <div className='main-content txt-c' >
     <ActivityList name='' url='/api/cars' done={refresh} stateHundler={stateHundler} onRefreshHundler={onRefreshHundler} headers={headers}/>
     </div>  
     <ModalUpdate modalState={modalState} inputs={inputsToUpdate}  selectors={selectorsToUpdate}  url='/api/cars' toUpdate={'/api/cars/'+ toUpdateId }  onRefreshHundler={onRefreshHundler} stateHundler={stateHundler} name={language[language.lang].modals[label].updateDescription} title={language[language.lang].modals[label].updateTitle} />
   </main>

 </Layout>
)
}

export default Cars