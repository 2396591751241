import  {useState ,useEffect} from 'react'
import language from '../../variables/language';
import '../../css/form.css'
import Selector from './Selector';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Feedback from './Feedback';
export default function Form(props) {
   const [errMsg, setErrMsg] = useState('');
   const [visibility, setVisibility] = useState('flex');
   const [status, setStatus] = useState('none');
   const [inputs,setInputs] = useState(props.inputs);
   const navigate = useNavigate();
 
   
  
   const handleInputChange = (event, fieldName) => {
      const updatedInputs = { ...inputs };
      updatedInputs[fieldName].value = event.target.value;
      setInputs(updatedInputs);
  };
  const selectorsHundler = ( selectors) =>{
   const updatedInputs = { ...inputs };
   if (updatedInputs.hasOwnProperty(selectors.name)) {
      // Jeśli pole już istnieje, aktualizujemy jego wartość
      updatedInputs[selectors.name].value = selectors.value;
  } else {
      // Jeśli pole nie istnieje, dodajemy nowe pole do obiektu inputs
      updatedInputs[selectors.name] = {
          value: selectors.value
      };
  }
      // console.log("selectors -> "+selectors.value );
      // console.log("Name -> "+selectors.name );
   setInputs(updatedInputs);
  }

  
   
   
   const submitHundler =(e)=>{
      e.preventDefault();
      const formData = {};
         // Zbieranie wartości z pól formularza
        // const inputsTosave = document.querySelectorAll('.submit-field');
        Object.keys(inputs).forEach(fieldName => {
           formData[fieldName] = inputs[fieldName].value;
         //  console.log(inputs[fieldName].name+ '<- is ->' + formData[fieldName]);
         });
        // console.log('end hundler -> size of inputs: '+Object.keys(inputs).length);
        if(props.mode === 'edit'){
         axios.put(props.url,
            JSON.stringify(formData), {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        ).then(response => {
           statusHundler('done');
        })
        .catch(err => {
          if (err.response) {
            if(err.response.status===403){
               statusHundler('forbidden');
            }else if(err.response.status=== 500){
               statusHundler('error');
            } else if(err.response.status === 401 ){
               navigate('/login');
            }
         } else {
             console.log('Błąd podczas wysyłania żądania:', err.message);
         }
        });
        setStatus('wait');
        }else{
         axios.post(props.url,
            JSON.stringify(formData), {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        ).then(response => {
           statusHundler('done');
        })
        .catch(err => {
          if (err.response) {
            
             if(err.response.status===403){
                statusHundler('forbidden');
             }else if(err.response.status=== 500){
                statusHundler('error');
             } else if(err.response.status === 401 ){
                navigate('/login');
             }
         } else {
             console.log('Błąd podczas wysyłania żądania:', err.message);
         }
        });
        setStatus('wait');
        }
         
   }
   const statusHundler=(status)=>{
      setStatus(status);
   }
   //status of form updater 
    useEffect(() => {
      if(status === 'none'){
         setVisibility('flex');
      }else if(status === 'wait'){
         setVisibility('none');
      }else if(status === 'done'){
         setVisibility('none');
         props.onRefreshHundler();
      }else if(status === 'error'){
         setVisibility('none');
      }else if(status === 'forbidden'){
         setVisibility('none');
      }else if(status === 'timeout'){
         setVisibility('none');
      }
    }, [status]);

    //init function sets inputs selectors to one data
    useEffect(() => {
      const updatedInputs = { ...inputs };
      Object.keys(props.selectors).forEach(fieldName => {
         if (inputs.hasOwnProperty(props.selectors[fieldName])) {
            updatedInputs[props.selectors[fieldName].name].value = props.selectors[fieldName].value;
            updatedInputs[props.selectors[fieldName].name].name = props.selectors[fieldName].name;
         } else {
            updatedInputs[props.selectors[fieldName].name] = {
               'value': props.selectors[fieldName].value,
               'name': props.selectors[fieldName].name
            };
         }
      });
      // Object.keys(props.inputs).forEach(fieldName => {
      //    if (inputs.hasOwnProperty(props.inputs[fieldName])) {
      //       updatedInputs[props.inputs[fieldName].name].value = props.inputs[fieldName].value;
      //       updatedInputs[props.inputs[fieldName].name].name = props.inputs[fieldName].name;
      //    } else {
      //       updatedInputs[props.inputs[fieldName].name] = {
      //          'value': props.inputs[fieldName].value,
      //          'name': props.inputs[fieldName].name
      //       };
      //    }
      // });
      setInputs(updatedInputs);

   }, [props.selectors]);


  return (
    <div style={{width:'100%'}}>
      <form className='form-container txt-c' style={{display:visibility}}>
         <h3 className='header-name'>{props.name}</h3>

            {/* inputs */}
            {Object.keys(props.inputs).map(field => (
               <div className='from-input-wraper' data-label={language[language.lang][props.inputs[field].name]} key={props.inputs[field].name}>
                  <input className='submit-field from-input txt-c'  required={true}
                  placeholder={language[language.lang][props.inputs[field].name]} 
                  key={props.inputs[field].name + 'inp'}
                  type={props.inputs[field].type}
                  name={props.inputs[field].name}
                  disabled={props.inputs[field].disabled}
               
                  value={inputs[field].value}
                  onChange={(e) => handleInputChange(e, inputs[field].name)}
                  data-label={language[language.lang][props.inputs[field].name]} />
               </div>
            ))}
       
            {/* selects */}
            {Object.keys(props.selectors).map(field => (
               <div className='from-input-wraper' key={props.selectors[field].name}  data-label={language[language.lang][props.selectors[field].name]}>
                   <Selector className='submit-field form-selection txt-c' key={'sel-'+props.selectors[field].name} selectorsHundler={selectorsHundler} value={props.selectors[field].value} name={props.selectors[field].name} url={props.selectors[field].url} /> 
               </div>
            ))}
      
            {/* submit button */}
            <button type='submit' className=' form-button txt-c' onClick={submitHundler}>
               {language[language.lang].save}
            </button>
     
      </form>
      <Feedback statusHundler={statusHundler} status={status} />
      
    </div>
  )
}
